import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.css'
/*
入口组件
 */

//将App组件标签渲染到index页面的div上
ReactDOM.render(<App />,document.getElementById('root'))
