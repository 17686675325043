/*
进行local数据存储管理的工具模块
*/
import store from 'store';

const BLOG_ID = 'blog_id'
const BLOG_ARTICLE_STYLE='blog_article_style'
export default {
    //保存blog_id
    saveBlogId(id) {
        store.set(BLOG_ID,id)
    },
    //读取blog_id
    getBlogId() {
        return store.get(BLOG_ID)||{}
    },
    //删除blog_id
    removeBlogId() {
        store.remove(BLOG_ID)
    },
    //保存blog_article_style
    saveBlogArticleStyle(article_style) {
        store.set(BLOG_ARTICLE_STYLE,article_style)
    },
    //读取blog_article_style
    getBlogArticleStyle() {
        return store.get(BLOG_ARTICLE_STYLE)||{}
    },
    //删除blog_article_style
    removeBlogArticleStyle() {
        store.remove(BLOG_ARTICLE_STYLE)
    },
}