import React, {Component} from 'react';
import './index.less'
import LoadingCom from '../../components/loading'
import {reqBanner, reqWorkExperience,reqBlogList,reqProjectExperience} from '../../api/index'
import {message, Carousel, Divider} from "antd";
import storageUtils from '../../utils/storageUtils'
import {Link} from "react-router-dom";
import PaginationAssembly from "../../components/pagination";

/*
* 公司信息路由*/
export default class Home extends Component {

    state = {
        bannerInformation: [],
        isLoading: true,
        work_experience: [],
        blogListInformation:[],
        project_experience:[],
        experience_total:0,
    }

    //发异步ajax请求
    componentDidMount() {
        this.getBanner()
        // this.getWorkExperience()
        // this.getBlogList()
        // this.getProjectExperience()
    }

    //为第一次render()准备数据
    componentWillMount() {
    }

    getBanner = async () => {
        //发异步ajax请求，获取数据
        const result = await reqBanner()
        this.getWorkExperience()
        if (result.status === 200) {
            const bannerInformation = result.data.results
            this.setState({
                bannerInformation: bannerInformation,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getWorkExperience = async () => {
        //发异步ajax请求，获取数据
        const result = await reqWorkExperience()
        this.getBlogList()
        if (result.status === 200) {
            const work_experience = result.data.results
            this.setState({
                work_experience: work_experience,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getProjectExperience = async () => {
        //发异步ajax请求，获取数据
        const result = await reqProjectExperience()
        if (result.status === 200) {
            const project_experience = result.data.results
            this.setState({
                isLoading:false,
                project_experience: project_experience,
                experience_total:result.data.count,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getBlogList = async () => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList()
        this.getProjectExperience()
        if (result.status === 200) {
            const blogListInformation = result.data.results
            this.setState({
                blogListInformation: blogListInformation,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getBlogDetail = (item) => {
        //删除旧数据
        storageUtils.removeBlogArticleStyle()
        storageUtils.removeBlogId()
        //保存新数据
        storageUtils.saveBlogId(item.id)
        storageUtils.saveBlogArticleStyle(item.article_style)
        this.props.history.push({
            pathname:'/detail',
        })
    }
    getChildren=(res)=>{
        this.setState({
            experience_total:res.data.count,
            project_experience: res.data.results,
        })
    }

    render() {
        //读取状态数据
        const {bannerInformation,work_experience,blogListInformation,project_experience} = this.state
        const total=this.state.experience_total
        return (
            <div className="home">
                {this.state.isLoading ? <LoadingCom/> : ''}
                <div style={{display: "flex", overflow: "hidden"}}>
                    <div className="home-list">
                        <Carousel autoplay
                                  dotPosition="top"
                        >
                            {
                                bannerInformation&&bannerInformation.map(item => {
                                    return (
                                        <div key={item.title}>
                                            <img src={item.img} alt={item.title} className="home-list-img"/>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    <div className="home-right">
                        <h2 style={{fontWeight: 800}}>最新消息</h2>
                        {
                            blogListInformation&&blogListInformation.map(item => {
                                return (
                                    <Link >
                                        <div key={item.title} className="home-right-list" onClick={()=>{this.getBlogDetail(item)}}>
                                            <div className="home-right-list-title">{item.title}</div>
                                            <div className='home-right-list-title-time'>{item.add_time}</div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{width: 850,marginBottom:100}}>
                    <Divider style={{fontWeight: 800}}>教育经历</Divider>
                    <ul style={{fontSize: 18, marginLeft: 30, listStyle: "disc"}}>
                        <li style={{display: "flex"}}>
                            <div>2013/09--2016/03</div>
                            <div style={{marginLeft: 50}}>天津工业大学</div>
                            <div style={{marginLeft: 50}}>硕士</div>
                        </li>
                        <li style={{display: "flex"}}>
                            <div>2008/09--2012/06</div>
                            <div style={{marginLeft: 50}}>北京林业大学</div>
                            <div style={{marginLeft: 50}}>本科</div>
                        </li>
                    </ul>
                    <Divider style={{fontWeight: 800}}>工作经验</Divider>
                    {
                        work_experience&&work_experience.map(item => {
                            return (
                                <ul style={{fontSize: 18,marginLeft: 30}} key={item.time}>
                                    <div style={{display: "flex", fontWeight: "bold", flexDirection: "row"}}>
                                        <div style={{flex: 1}}>{item.time}</div>
                                        <div style={{flex: 1}}>{item.company}</div>
                                        <div style={{flex: 1}}>{item.position}</div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{
                                        __html:item.content
                                    }}></div>
                                </ul>
                            )
                        })
                    }
                    <Divider style={{fontWeight: 800}}>项目经验</Divider>
                    {
                        project_experience&&project_experience.map(item => {
                            return (
                                <ul style={{fontSize: 18,marginLeft: 30}} key={item.time}>
                                    <div style={{display: "flex", fontWeight: "bold", flexDirection: "row"}}>
                                        <div style={{flex: 1}}>{item.time}</div>
                                        <div style={{flex: 1}}>{item.title}</div>
                                        <div style={{flex: 1}}>{item.position}</div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{
                                        __html:item.content
                                    }}></div>
                                </ul>
                            )
                        })
                    }
                    <div className='Pagination'>
                        <PaginationAssembly datas={[{total},'首页']} getChildrenHomeExperience={this.getChildren}></PaginationAssembly>
                    </div>

                </div>
            </div>
        )
    }
}

