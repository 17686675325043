import React, {Component} from 'react';
import {reqWeather} from '../../api/index';
import {withRouter, Link} from 'react-router-dom';
import '../header/index.less';
import {formateDate} from '../../utils/dateUtils'
import {
    Icon,
    Menu,
    Row,
} from 'antd';
import Logo from '../../assets/images/logo.jpg'

/*头部导航的组件*/

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: formateDate(Date.now()),//当前时间字符串
            temperature:'',//温度
            weather:'',//天气
            winddirection:'',//风向
            windpower:'',//等级
            isMobile: false,
            visible: false,
            placement: 'top',
            current: null,
            menuCurrent: '',
            nav: '首页',
            navTitle: '首页',
            code: '',
            isLoading: false,
        };
        this.initMenu = this.initMenu.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        this.initMenu(nextProps.location.pathname);
    }

    /*
    * 第一次render()之后执行一次
    * 一般在此执行异步操作，发ajax请求/启动定时器*/
    componentDidMount() {
        //获取当前的时间
        this.getTime()
        //获取天气
        this.getWeather()
        //初始化导航菜单
        this.initMenu(this.props.pathname);
    }

    /*
    当前组件卸载之前调用
    */
    componentWillUnmount() {
        //清楚定时器
        clearInterval(this.intervalID)
    }

    getTime = () => {
        //每隔1s获取当前时间，并更新状态数据currentTime
        this.intervalID = setInterval(() => {
            const currentTime = formateDate(Date.now())
            this.setState({currentTime})
        }, 1000)
    }
    getWeather = async () => {
        //调用接口请求异步获取数据
        const {temperature, weather,winddirection,windpower} = await reqWeather('南京')
        //更新状态
        this.setState({temperature, weather,winddirection,windpower})
    }

    initMenu(name) {
        let key = '8';
        let navTitle = '';
        if (name === '/') {
            key = '8';
            navTitle = '首页';
        } else if (name === '/product') {
            key = '1';
            navTitle = '产品';
        } else if (name === '/operate') {
            key = '2';
            navTitle = '运营';
        } else if (name === '/front') {
            key = '3';
            navTitle = '前端';
        } else if (name === '/back') {
            key = '4';
            navTitle = '后端';
        } else if (name === '/essay') {
            key = '5';
            navTitle = 'essay';
        } else if (name === '/history') {
            key = '6';
            navTitle = '历史';
        } else if (name === '/chat') {
            key = '7';
            navTitle = '闲聊';
        }
        this.setState({
            navTitle,
            menuCurrent: key,
        });
    }


    handleMenu = e => {
        console.log('click ', e.key);
        this.setState({
            menuCurrent: e.key,
        });
    };


    render() {
        const {currentTime, temperature, weather,winddirection,windpower} = this.state
        return (
            <div className="header">
                <Row className="container">
                    <div className="container-left">
                                <img src={Logo} alt=""/>
                    </div>
                    <div className="container-middle">
                        <Menu
                            theme="light"
                            mode="horizontal"
                            defaultSelectedKeys={['1']}
                            onClick={this.handleMenu}
                            selectedKeys={[this.state.menuCurrent]}
                            style={{lineHeight: '64px', borderBottom: 'none'}}
                        >
                            <Menu.Item key="8">
                                <Link to="/">
                                    <Icon type="home" theme="outlined"/>
                                    首页
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="1">
                                <Link to="/product">
                                    <Icon type="ordered-list" theme="outlined"/>
                                    产品
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/operate">
                                    <Icon type="fire" theme="outlined"/>
                                    运营
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/front">
                                    <Icon type="project" theme="outlined"/>
                                    前端
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to="/back">
                                    <Icon type="database" theme="outlined"/>
                                    后端
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <Link to="/essay">
                                    <Icon type="hourglass" theme="outlined"/>
                                    随笔
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to="/history">
                                    <Icon type="message" theme="outlined"/>
                                    历史
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="7">
                                <Link to="/chat">
                                    <Icon type="user" theme="outlined"/>
                                    闲聊
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className="container-right">
                        <div className="header-bottom">
                            <span>{currentTime}</span>
                        </div>
                        <div className="header-bottom-right">
                            <span>气温:{temperature}</span>
                            <span>风向:{winddirection}</span>
                            <span>风力:{windpower}</span>
                            <span>天气:{weather}</span>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }

}

export default withRouter(Header)
