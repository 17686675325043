import React, {Component} from 'react';
import './index.less'
import PaginationAssembly from "../../components/pagination";
import LoadingCom from '../../components/loading'
import {reqBlogList_Essay} from "../../api";
import {message} from "antd";
import storageUtils from "../../utils/storageUtils";
import {Link} from "react-router-dom";
/*
* 公司信息路由*/
export default class Index extends Component {

    state = {
        essayInformation: [],//总的公司信息列表
        isLoading: true,
        total:0,
    }

    //发异步ajax请求
    componentDidMount() {
        this.getContent()
    }

    //为第一次render()准备数据
    componentWillMount() {
    }

    getContent = async () => {

        //发异步ajax请求，获取数据
        const result = await reqBlogList_Essay()
        if (result.status === 200) {
            const essayInformation = result.data.results
            this.setState({
                total:result.data.count,
                essayInformation: essayInformation,
                isLoading: false,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getChildren=(res)=>{
        this.setState({
            total:res.data.count,
            essayInformation: res.data.results,
        })
    }

    getBlogDetail = (item) => {
        //删除旧数据
        storageUtils.removeBlogArticleStyle()
        storageUtils.removeBlogId()
        //保存新数据
        storageUtils.saveBlogId(item.id)
        storageUtils.saveBlogArticleStyle(item.article_style)
        this.props.history.push({
            pathname:'/detail',
        })
    }

    render() {
        //读取状态数据
        const datas = this.state.essayInformation
        const total=this.state.total
        return (
            <div>
                <div className="essay">
                    {this.state.isLoading ? <LoadingCom/> : ''}
                    {
                        datas&&datas.map(item => {
                            return (
                                <Link>
                                    <div className="essay-list" key={item.id} onClick={()=>{this.getBlogDetail(item)}}>
                                        <div className="essay-list-img"><img src={item.img} alt=""/></div>
                                        <div className="essay-list-content">
                                            <div className="essay-list-content-title">{item.title}</div>
                                            <div className="essay-list-content-abstract">{item.desc}</div>
                                            <div className="essay-list-content-time">{item.add_time}</div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='Pagination'>
                    <PaginationAssembly datas={[{total},'随笔']} getChildrenEssay={this.getChildren}></PaginationAssembly>
                </div>
            </div>
        )
    }
}

