import {message, Pagination} from 'antd';
import React, {Component} from "react";
import {reqBlogList_Operate, reqBlogList_Product,reqBlogList_Front,reqBlogList_Back,reqBlogList_Essay,reqBlogList_History,reqBlogList_Chat,reqProjectExperience} from "../../api";
import LoadingCom from '../../components/loading'

class PaginationAssembly extends Component {
    state = {
        isLoading: false,
    }

    getChange=(page,datas)=>{
        this.setState({
            isLoading:true,
        })
        if(datas[1]==='产品'){
            this.getProduct(page)
        }
        else if(datas[1]==='运营'){
            this.getOperate(page)
        }
        else if(datas[1]==='前端'){
            this.getFront(page)
        }
        else if(datas[1]==='后端'){
            this.getBack(page)
        }
        else if(datas[1]==='随笔'){
            this.getEssay(page)
        }
        else if(datas[1]==='历史'){
            this.getHistory(page)
        }
        else if(datas[1]==='闲聊'){
            this.getChat(page)
        }
        else if(datas[1]==='首页'){
            this.getHomeExperience(page)
        }

    }

    getProduct = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Product(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenProduct(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getOperate = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Operate(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenOperate(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getFront = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Front(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenFront(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getBack = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Back(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenBack(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getEssay = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Essay(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenEssay(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getHistory = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_History(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenHistory(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getChat = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Chat(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenChat(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    getHomeExperience = async (page) => {
        //发异步ajax请求，获取数据
        const result = await reqProjectExperience(page)
        if (result.status === 200) {
            this.setState({
                isLoading:false,
            })
            this.props.getChildrenHomeExperience(result)
        } else {
            message.error("服务器请求错误！")
        }
    }

    render() {
        const datas=this.props.datas
        const total=datas[0].total
        return (
            <div>
                {this.state.isLoading ? <LoadingCom /> : ''}
                <Pagination defaultCurrent={1} total={total} pageSize={6} onChange={(page)=>{this.getChange(page,datas)}} />
            </div>
        );
    }
}

export default PaginationAssembly;
