/*
能发送异步ajax请求的函数模块
封装axios库
函数的返回值是Promise对象
1、优化：统一处理请求异常
    在外层包一个自己创建的promise对象
    在请求出错时，不去reject（error），而是显示错误提示
* */
import axios from 'axios';
import {message} from 'antd';


export default function ajax(url, data = {}, method = 'GET') {

    // const TOKEN_KEY = 'AS_MALL_ACCESS_TOKEN';
    // axios.defaults.headers.common['Authorization'] = 'JWT' + ' ' + storageUtils.getToken(TOKEN_KEY);
    return new Promise((resolve, reject) => {

        let promise
        //1、执行异步ajax请求
        if (method === 'GET') {
            //发GET请求
            promise = axios.get(url, {
                //配置对象
                params: data//指定请求参数
            })
        } else if (method === 'PUT') {
            //发PUT请求
            promise = axios.put(url, data,)
        } else {
            //发POST请求
            promise = axios.post(url, data)
        }
        //2、如果成功了，调用resolve(value)
        promise.then(response => {
            resolve(response)
            //3、如果失败了，不调用reject(reason)，而是提示异常信息
        }).catch(error => {
            // reject(error)
            message.error('请求出错' + error.message)
        })
    })


}
