import React, {Component} from 'react';
import './index.less'
import {reqBlogList_Back} from "../../api";
import {message} from "antd";
import LoadingCom from '../../components/loading'
import PaginationAssembly from "../../components/pagination";
import storageUtils from "../../utils/storageUtils";
import {Link} from "react-router-dom";
/*
* 公司信息路由*/
export default class Index extends Component {

    state = {
        backInformation: [],//总的公司信息列表
        isLoading: true,
        total:0,
    }

    //发异步ajax请求
    componentDidMount() {
        this.getContent()
    }

    //为第一次render()准备数据
    componentWillMount() {
    }

    getContent = async () => {

        //发异步ajax请求，获取数据
        const result = await reqBlogList_Back()
        if (result.status === 200) {
            const backInformation = result.data.results
            this.setState({
                total:result.data.count,
                backInformation: backInformation,
                isLoading:false,
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getChildren=(res)=>{
        this.setState({
            total:res.data.count,
            backInformation: res.data.results,
        })
    }

    getBlogDetail = (item) => {
        //删除旧数据
        storageUtils.removeBlogArticleStyle()
        storageUtils.removeBlogId()
        //保存新数据
        storageUtils.saveBlogId(item.id)
        storageUtils.saveBlogArticleStyle(item.article_style)
        this.props.history.push({
            pathname:'/detail',
        })
    }


    render() {
        //读取状态数据
        const datas=this.state.backInformation
        const total=this.state.total
        return (
            <div>
                <div className="back">
                    {this.state.isLoading ? <LoadingCom /> : ''}
                    {
                        datas&&datas.map(item=>{
                            return(
                                <Link>
                                    <div className="back-list" key={item.id} onClick={()=>{this.getBlogDetail(item)}}>
                                        <div className="back-list-left">
                                            <div className="back-list-left-tittle">{item.title}
                                            </div>
                                            <div className="back-list-left-abstract">{item.desc}
                                            </div>
                                            <div className="back-list-left-time">{item.add_time}</div>
                                        </div>
                                        <div className="back-list-right">
                                            <img src={item.img} alt="" className="back-list-right-img"/>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    <div className='Pagination'>
                        <PaginationAssembly datas={[{total},'后端']} getChildrenBack={this.getChildren}></PaginationAssembly>
                    </div>
                </div>
            </div>
        )
    }
}

