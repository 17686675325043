import React, {Component} from 'react';
import './index.less'
import storageUtils from '../../utils/storageUtils'
import {
    reqBlogList_Back_Detail, reqBlogList_Chat_Detail,
    reqBlogList_Essay_Detail,
    reqBlogList_Front_Detail, reqBlogList_History_Detail,
    reqBlogList_Operate_Detail,
    reqBlogList_Product_Detail
} from "../../api";
import {message} from "antd";
import LoadingCom from "../../components/loading";
/*
* 公司信息路由*/
export default class Index extends Component {

    state = {
        datas:'',
        isLoading:true,
        content:'',
    }

    //发异步ajax请求
    componentDidMount() {

    }

    //为第一次render()准备数据
    componentWillMount() {
        const BlogArticleStyle=storageUtils.getBlogArticleStyle()
        const BlogId=storageUtils.getBlogId()
        console.log(BlogArticleStyle,BlogId)
        if(BlogArticleStyle==='产品'){
            this.getProductDetail(BlogId)
        }
        else if(BlogArticleStyle==='运营'){
            this.getOperateDetail(BlogId)
        }
        else if(BlogArticleStyle==='前端'){
            this.getFrontDetail(BlogId)
        }
        else if(BlogArticleStyle==='后端'){
            this.getBackDetail(BlogId)
        }
        else if(BlogArticleStyle==='随笔'){
            this.getEssayDetail(BlogId)
        }
        else if(BlogArticleStyle==='历史'){
            this.getHistoryDetail(BlogId)
        }
        else if(BlogArticleStyle==='闲聊'){
            this.getChatDetail(BlogId)
        }

    }

    getProductDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Product_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getOperateDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Operate_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getFrontDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Front_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getBackDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Back_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getEssayDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Essay_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getHistoryDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_History_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    getChatDetail = async (BlogId) => {
        //发异步ajax请求，获取数据
        const result = await reqBlogList_Chat_Detail(BlogId)
        if (result.status === 200) {
            const content=this.changeSrc(result.data.content)
            this.setState({
                isLoading:false,
                content:content,
                datas:result.data
            })
        } else {
            message.error("服务器请求错误！")
        }
    }

    //修改img的src
    changeSrc(data){
        return data.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, capture)=>{
            const realSrc=`https://admin.njcloud.top`+capture
            // return `<img src=${realSrc} />`
            return `<img src='${capture.indexOf('admin.njcloud.top') > -1 ? capture : 'https://admin.njcloud.top' + capture}' />`
        })
    }

    render() {
        //读取状态数据
        const datas = this.state.datas
        const content = this.state.content
        return (
            <div className="detail">
                {this.state.isLoading ? <LoadingCom/> : ''}
                <div>
                    <h1 className="title">{datas.title}</h1>
                </div>
                <div className="timeandcategory">
                    {this.state.datas?
                        <div>
                            <div className="time">添加日期：{datas.add_time}</div>
                            <div className="category">分类：{datas.article_style}</div>
                        </div>:''}
                </div>
                <div dangerouslySetInnerHTML={{
                    __html:content
                }} className="content"></div>
            </div>
        )
    }
}

