import jsonp from 'jsonp'
import {message} from 'antd';
import ajax from './ajax';

/*
包含应用中所有接口请求函数的模块
每个函数的返回值都是Promise

基本要求：能根据接口文档定义接口请求函数
 */

const BASE = 'https://admin.njcloud.top'

/**
 * 官网
 */

//登录
// export const reqLogin = (username, password) => ajax(BASE + 'api/companylogin', {username, password}, 'POST')//和上面的写法效果一致

/*
* 导航栏信息*/
/*
* 线上打开，线下关闭*/
export const reqBanner = () => ajax(BASE + '/blog/banner/',{})
export const reqWorkExperience = () => ajax(BASE + '/myself/work_experience/',{})//工作经验
export const reqProjectExperience = (page) => ajax(BASE + '/myself/project_experience/',{page})//项目经验
export const reqBlogList = () => ajax(BASE + '/blog/bloglist/',{})//博客列表
export const reqBlogList_Product = (page) => ajax(BASE + '/blog/bloglist_product/',{page})//博客列表--产品
export const reqBlogList_Operate = (page) => ajax(BASE + '/blog/bloglist_operate/',{page})//博客列表--运营
export const reqBlogList_Front = (page) => ajax(BASE + '/blog/bloglist_front/',{page})//博客列表--前端
export const reqBlogList_Back = (page) => ajax(BASE + '/blog/bloglist_back/',{page})//博客列表--后端
export const reqBlogList_Essay = (page) => ajax(BASE + '/blog/bloglist_essay/',{page})//博客列表--随笔
export const reqBlogList_History = (page) => ajax(BASE + '/blog/bloglist_history/',{page})//博客列表--历史
export const reqBlogList_Chat = (page) => ajax(BASE + '/blog/bloglist_chat/',{page})//博客列表--闲聊

export const reqBlogList_Product_Detail = (id) => ajax(BASE + '/blog/bloglist_product/'+id+'/')//博客列表--产品详情
export const reqBlogList_Operate_Detail = (id) => ajax(BASE + '/blog/bloglist_operate/'+id+'/')//博客列表--运营详情
export const reqBlogList_Front_Detail = (id) => ajax(BASE + '/blog/bloglist_front/'+id+'/')//博客列表--前端详情
export const reqBlogList_Back_Detail = (id) => ajax(BASE + '/blog/bloglist_back/'+id+'/')//博客列表--后端详情
export const reqBlogList_Essay_Detail = (id) => ajax(BASE + '/blog/bloglist_essay/'+id+'/')//博客列表--随笔详情
export const reqBlogList_History_Detail = (id) => ajax(BASE + '/blog/bloglist_history/'+id+'/')//博客列表--历史详情
export const reqBlogList_Chat_Detail = (id) => ajax(BASE + '/blog/bloglist_chat/'+id+'/')//博客列表--闲聊详情
/*

/*
* 线下打开，线上关闭*/
// export const reqBanner = () => ajax(BASE + '/blog/banner/',{})
// export const reqWorkExperience = () => ajax(BASE + '/myself/work_experience/',{})//工作经验
// export const reqProjectExperience = (page) => ajax(BASE + '/myself/project_experience/',{page})//项目经验
// export const reqBlogList = () => ajax(BASE + '/blog/bloglist/',{})//博客列表
// export const reqBlogList_Product = (page) => ajax(BASE + '/blog/bloglist_product/',{page})//博客列表--产品
// export const reqBlogList_Operate = (page) => ajax(BASE + '/blog/bloglist_operate/',{page})//博客列表--运营
// export const reqBlogList_Front = (page) => ajax(BASE + '/blog/bloglist_front/',{page})//博客列表--前端
// export const reqBlogList_Back = (page) => ajax(BASE + '/blog/bloglist_back/',{page})//博客列表--后端
// export const reqBlogList_Essay = (page) => ajax(BASE + '/blog/bloglist_essay/',{page})//博客列表--随笔
// export const reqBlogList_History = (page) => ajax(BASE + '/blog/bloglist_history/',{page})//博客列表--历史
// export const reqBlogList_Chat = (page) => ajax(BASE + '/blog/bloglist_chat/',{page})//博客列表--闲聊
//
// export const reqBlogList_Product_Detail = (id) => ajax(BASE + '/blog/bloglist_product/'+id,{id})//博客列表--产品详情
// export const reqBlogList_Operate_Detail = (id) => ajax(BASE + '/blog/bloglist_operate/'+id,{id})//博客列表--运营详情
// export const reqBlogList_Front_Detail = (id) => ajax(BASE + '/blog/bloglist_front/'+id,{id})//博客列表--前端详情
// export const reqBlogList_Back_Detail = (id) => ajax(BASE + '/blog/bloglist_back/'+id,{id})//博客列表--后端详情
// export const reqBlogList_Essay_Detail = (id) => ajax(BASE + '/blog/bloglist_essay/'+id,{id})//博客列表--随笔详情
// export const reqBlogList_History_Detail = (id) => ajax(BASE + '/blog/bloglist_history/'+id,{id})//博客列表--历史详情
// export const reqBlogList_Chat_Detail = (id) => ajax(BASE + '/blog/bloglist_chat/'+id,{id})//博客列表--闲聊详情
/*
json请求的接口请求函数
 */
export const reqWeather = (city) => {
    return new Promise((resolve, reject) => {
        const url = `https://restapi.amap.com/v3/weather/weatherInfo?city=320100&key=19d9ca53672b481ac8afff2dde0d81df`
        //发送jsonp请求
        jsonp(url, {}, (err, data) => {
            //如果成功了
            if (!err && data.status === '1') {
                //取出需要的数据
                const {temperature, weather,winddirection,windpower} = data.lives[0]
                resolve({temperature, weather,winddirection,windpower})
            } else {
                //如果失败了
                message.error('获取天气信息失败')
            }
        })

    })
}
