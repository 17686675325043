import React, {Component} from 'react';
import {Layout} from 'antd';
import Header from '../../components/header';
import {Route, Switch} from 'react-router-dom';
import './index.less'
import avatar from '../../assets/images/avatar.jpg'
import weixin from '../../assets/images/weixin.png'
import Product from '../product';
import Operate from '../operate'
import Front from '../front'
import Back from '../back'
import Essay from '../essay'
import History from '../history'
import Chat from '../chat'
import Home from '../home'
import Detail from '../detailBlog'

const {Footer, Content} = Layout;
/*
* 后台主页的路由组件*/
export default class Index extends Component {
    render() {
        return (
                <Layout style={{height:"100%",backgroundColor:"#fff"}}>
                    <Header/>
                    <Content className="layout">
                        <Switch>
                            <Route path='/product' component={Product}/>
                            <Route path='/operate' component={Operate}/>
                            <Route path='/front' component={Front}/>
                            <Route path='/back' component={Back}/>
                            <Route path='/essay' component={Essay}/>
                            <Route path='/history' component={History}/>
                            <Route path='/chat' component={Chat}/>
                            <Route path='/detail' component={Detail}/>
                            <Route path='/' component={Home}/>
                        </Switch>
                        <div className="right">
                            <img src={avatar} alt="头像" className="avatar"/>
                            <div className="name">杨家三少</div>
                            <div className="content">
                                <div></div>
                                <div>
                                    <div>QQ:</div>
                                    <div>769892380</div>
                                </div>
                                <div>
                                    <div>微信:</div>
                                    <div style={{width:200,marginLeft:70}}><img src={weixin} alt="微信"/></div>
                                </div>
                                <div>
                                    <div>快来加我好友吧</div>
                                </div>
                            </div>
                        </div>
                    </Content>
                    {/*<Footer style={{textAlign: 'center',backgroundColor: "#fff",borderTop: "1px solid #eeeeee",position:"fixed",bottom:0,left:0,right:0}}>南京今科文化传播有限公司</Footer>*/}
                </Layout>
        )
    }
}
